h1 {
  padding-top: 1rem !important;
}
:host ::ng-deep .mat-mdc-form-field .mat-mdc-text {
margin:  0px;
padding: 0px;
}

.mat-calendar-content{
  padding: 1rem !important;
  margin-top: -1.5rem !important;
}

.mat-date-range-input-separator{
  margin: 0px 4px 0px 4px!important; 
}

.uitk-c-checkbox__label{
  font-size: 0.7rem !important;
}

//checkmark selection issue when more than 3 row wrap for uitk bropdown options
.uitk-c-dropdown__option .uitk-c-checkbox:checked+.uitk-c-checkbox__label:after {
  // box-sizing: border-box !important;
  align-self: center !important;
}

.mat-date-range-align{
  margin-top: 0.3rem;
  margin-left: 0.6rem;
  height: 2.25rem;
  flex-wrap: wrap; 
  width: 220px !important; 
  background-color: #fff;
}

.mat-mdc-text-field-wrapper:not(#createddaterangeid),.mdc-text-field:not(#createddaterangeid),.mdc-text-field--filled:not(#createddaterangeid), .mdc-text-field--no-label:not(#createddaterangeid)
.uitk-c-panel__header-title{
    padding:0rem !important;
}
.uitk-c-panel__header {
  padding: 0rem !important;
}
#header-title_search .uitk-c-form-field{
    flex-direction:row !important;
}
.btn-opacity-shadow {
  opacity: 1 !important;
  box-shadow: inset 0 0 0 1px #0c55b8 !important;
}

#created-date-singleselect .uitk-c-form__control
{
    height:40px !important;
}
#singleselect-ReviewType .uitk-c-form__control{
    height:34px !important;
}
.uitk-c-panel__header-title h1 {
  color: #282a2e !important;
  font-family: Arial, Helvetica, sans-serif  !important;
  margin: 0  !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
}

#globalpanel {
  display: inline-flex;
  margin-left: 20px !important;
}
#searchtype-txt-lbl {
  min-width: 40px !important;
  padding-top: 14px !important;
  padding-left: 2px !important;
}
#searchtype-txt {
  height: 34px !important;
  margin-left: 8px !important;
  margin-top: 0.4rem !important;
  width: 67% !important;
  font-size: 12px !important;
}

#created-date-singleselect-lbl {
  padding-top: 15px !important;
  min-width: 100px !important;
  padding-left: 12px !important;
}
#created-date-singleselect {
  width: 148px !important;
  margin-left: 5px !important;
  text-align: left !important;
}
#singleselect-ReviewType-lbl {
  padding-top: 14px !important;
  padding-left: 15px !important;
}
#singleselect-ReviewType {
 /* z-index: 9999 !important;*/
  margin-left: 4px !important;
  width: 154px !important;
  text-align: left !important;
}
#created-date-singleselect span{
    font-size:12px !important;
}
#singleselect-ReviewType span {
  font-size: 12px !important;
}

#createddaterangeid .mat-calendar {
  z-index: 99999 !important;  
}
#createddateid {
  margin-top: 0.75rem;
}
.mat-form__control--error {
  background-color: #FAEDEC !important;
  box-shadow: 0 0 0 1px #e32315;
}
.uitk-c-form__control--error {
  margin-bottom: inherit !important;
}

.uitk-c-form__control{
  margin-top: .2rem !important;
}
