#basic-table, #row-data a{
  color: #0056b3 !important;
  cursor: pointer;  
}

.searchPanel .uitk-c-panel__content {
  overflow-y: visible !important;
}

.searchPanel .uitk-c-panel__content-container {
  padding: 1rem 0rem 1rem;
  border: 1px solid #d0d0ce;
}

.searchPanel .uitk-c-panel__header--accordion-open > .uitk-c-panel__header {
  border-right: 1px solid #d0d0ce;
}

.searchPanel .uitk-c-panel__header-title h1 {
  font-size: 1.2rem;
}

.elemwidth {
  width: 10.8%;
  flex: 0 0 10.8%;
  max-width: 10.8%;
  padding: 0.85rem;
}

.toggle-active-inactive {
  width: 8%;
  flex: 0 0 8%;
  max-width: 8%;
  padding-top:14px !important;
}

.smallFont * {
  font-size: 0.75rem !important;
}

.elemwidth .uitk-c-form-field {
  display: block;
  flex-direction: row;
  height: 76px;
}
.elemwidth .uitk-c-form__control {
  font:0.75rem Arial, Helvetica, sans-serif;
  height: 38px;
}

.elemwidth .uitk-c-date-picker__input {
    min-height: 1.5rem;
}

.searchPanel .uitk-c-panel__header {
  background-color: #f7f7f7;
  border-color: #0c55b8;
  border-left-width: thick;
}

#singleselect-UnlockCase select {
  height: 35px !important;
}
.global-search-label {
  font-size: 12px;    
}



:host ::ng-deep #myDialog-1 .uitk-c-dialog {
  width: 30% !important;
}


.form-content {
  margin-left: 6px !important;
}

.p-content {
  margin-left: 6px !important;
  font-size: 16px !important;
}

.uitk-c-dialog__content {
  overflow-y: unset !important;
}

.u-dropdown {
  margin-bottom: 6px;
}

.uitk-c-dropdown__container {
  max-height: 14.75rem !important;
}

.uitk-c-dialog__actions {
  display: block;
}

.page-title {
  flex: 1;
}
  .uitk-u-overlay {
    z-index: 9999;
  }
  #myDialog-1_closeBtn{
   
      color: black;
      box-shadow: inset 0 0 0 1px black;
      font-weight: 400;
      background: transparent;
  
  }
  #myDialog-1_closeBtn:hover{
    color: #FFFFFF !important;
    background: #002677 !important;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #002677 !important;
    outline: none !important;
  }
  
#showCaseInUseDialog .uitk-c-dialog {
  width: 30%;
}
.uitk-c-panel__header-title{
    width:100% !important;
}
#header-title_search{
    width:100% !important;
    display:inline-flex;
    float:left;width:101.5%;
    height:60px !important;
}
#tk-panl-header_search button {
  pointer-events: none;
  cursor: not-allowed;  
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
#basic-table{
    z-index:0;
}
#globalsearchid {
  margin-bottom: 4px !important;
}
#SearchButtonPanel {
  padding-left: 1rem !important;
  display: flex !important;
  max-height: 3.5rem !important;
  padding-top: 2px !important;
}
#headerpanel {
  width: 100%;
  display: inline-flex !important;
}
#advancepanel .uitk-c-panel {
  border: 0px !important;
  border-radius: 0px !important;
  border-color:#ffffff !important;
}
#advanceuitkpanel .uitk-c-panel__header {
  background-color: #ffffff !important;
  border-color: #ffffff !important
}
#advanceuitkpanel .uitk-c-panel__content-container {
  border-right: none !important;
  border-left: none !important;
  border-bottom:none !important;
  margin-left: 11px !important;
  padding: 0px !important;
}

#advanceuitkpanel .uitk-c-panel__header:focus {
  box-shadow: 0 0 0 0px #ffffff !important;
}

#advanceuitkpanel .uitk-c-panel__header:hover {
  box-shadow: 0 0 0 0px #ffffff !important;
}
#header-title_advanceuitkpanel{
    width:100%
}
.advancedheadertitle {
  display: inline-flex;
  width: 100%;
  min-height: 3.7rem;
}
.advanced-header {
  font-size: 1.2rem;
  padding-top: 17px !important;
  width: 185px !important;
}
.advanced-unclickable-panel {
  display: inline-flex;
  width: 90%;
  cursor: context-menu !important;
  max-height: 3.5rem;
}

.loader {
  position: fixed;
  z-index:99;
  top: 0%;
  left:0%;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.uitk-c-component-load-indicator__overlay {
  background-color: rgba(241, 239, 239, 0.4);
  position: absolute;
  opacity: 0.9;
  width: 100%;
  height: 100%;
}
#activitycolid {
  font-size: 16px !important;
  font-weight: 400 !important;
}
#activity-tooltip {
  background-color: white !important;
  color: black !important;
}

.activity-tootip-content {
  margin-left: 0px;
  padding-left: 12px;
  font-size: 12px;
  line-height: 1rem;
}
.activity-col-val {
  vertical-align: bottom !important;
  font-weight: bold;
}

.sml-icon{
  font-size: medium !important;
  font-weight: bold !important;
}

.activity-tootip-content-align{
  padding-bottom: 5px;
}

.flex-container {
  display: flex;
  align-items: flex-end;
}

.header-toggle {
  padding-bottom: 10px;
}

.past-due {
  color: red;
  font-weight: bold;
}

#admitdate_tool_tip .tk-tooltip {
  border-bottom: none !important;
}

#payerRefusal_tool_tip .tk-tooltip {
  border-bottom: none !important;
}

.payerApproved {
  color: #28a745 !important;
  font-size: medium !important;
  font-weight: lighter !important;
}

#lockIcon{
  padding-left: 4px !important;
}
#notification_tool_tip .tk-tooltip {
  border-bottom: none !important;
}
