/*@tooltipBorder: 9px solid transparent;

.checkBase(@base) when (@base < 1rem) {
  @tooltipBorder: 10px solid transparent;
}*/

.tk-tooltip {
  /* .checkBase(@font-size-base);*/
  position: relative;
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: 1px dotted gray;
  text-decoration: none;
}

.tk-tooltip:focus {
  /*.drop-shadow(@drop-shadow-focus);*/
  border: 1px solid black;
  outline: none;
}

.tk-tooltiptext {
  /*.drop-shadow(@drop-shadow-base);*/
  display: none;
  width: auto;
  color: black;
  background-color: white;
  padding: 10px;
  position: absolute;
  z-index: 999999;
  border: 1px solid #d0d0ce;
  padding-bottom: 0px;
}

.tk-tooltip .tk-tooltip-top,
.tk-tooltip .tk-tooltip-bottom {
  margin-left: -4.0 - 0.2;
}

.tk-tooltip .tk-tooltip-top {
  bottom: 150%;
}

.tk-tooltip .tk-tooltip-top-left {
  bottom: -20%;
  right: 100%;
  margin-right: 2px;
}

.tk-tooltip .tk-tooltip-bottom {
  top: 165%;
}

.tk-tooltip .tk-tooltip-bottom-right {
  top: -5px;
  left: 100%;
  margin-left: 2px;
}

.tk-tooltip .tk-tooltip-top-right {
  bottom: -20%;
  left: 100%;
  margin-left: 2px;
}

.tk-tooltip .tk-tooltip-bottom-left {
  top: -5px;
  right: 100%;
  margin-right: 2px;
}

.tk-tooltip .tooltipEllipses-tip.tk-tooltip-bottom-left {
  right: 100%;
  margin-right: 2px;
}

.tk-tooltip .tooltipEllipses-tip.tk-tooltip-bottom-right {
  left: 100%;
  margin-left: 2px;
}

.tk-tooltip .tk-tooltiptext::after,
.tk-tooltip .tk-tooltiptext::before {
  content: "";
  position: absolute;
  border-style: solid;
  border: 9px solid transparent;
}

.tk-tooltip .tk-tooltiptext::before {
  border-width: 1.0 + 0.07;
}

.tk-tooltip .tk-tooltiptext::after {
  border-width: 1px;
}

.tk-tooltip .tk-tooltip-top::before {
  left: 4px;
  top: 100%;
  border-top-color: #d0d0ce;
}

.tk-tooltip .tk-tooltip-top::after {
  top: 100%;
  left: 4px + 0.075;
  border-color: white transparent transparent transparent;
}

.tk-tooltip .tk-tooltip-bottom::before {
  left: 4px;
  bottom: 100%;
  border-bottom-color: #d0d0ce;
}

.tk-tooltip .tk-tooltip-bottom::after {
  bottom: 100%;
  left: 4 + 0.075;
  border-color: transparent transparent white transparent;
}

.tk-tooltip .tk-tooltip-bottom-right::before {
  top: 1px;
  right: 100%;
  border-width: 1 + 0.07;
  border-right-color: #d0d0ce;
}

.tk-tooltip .tk-tooltip-bottom-right::after {
  top: 1 + 0.06;
  right: 100%;
  border-color: transparent white transparent transparent;
}

.tk-tooltip .tk-tooltip-top-right::before {
  bottom: 1px;
  right: 100%;
  border-width: 1 + 0.07;
  border-right-color: #d0d0ce;
}

.tk-tooltip .tk-tooltip-top-right::after {
  bottom: 1 + 0.07;
  right: 100%;
  border-color: transparent white transparent transparent;
}

.tk-tooltip .tk-tooltip-bottom-left::before {
  top: 1px;
  left: 100%;
  border-width: 1 + 0.07;
  border-left-color: #d0d0ce;
}

.tk-tooltip .tk-tooltip-bottom-left::after {
  top: 1 + 0.06;
  left: 100%;
  border-color: transparent transparent transparent white;
}

.tk-tooltip .tk-tooltip-top-left::before {
  bottom: 1px;
  left: 100%;
  border-width: 1 + 0.07;
  border-left-color: #d0d0ce;
}

.tk-tooltip .tk-tooltip-top-left::after {
  bottom: 1 + 0.07;
  left: 100%;
  border-color: transparent transparent transparent white;
}

.tk-tooltip:hover .tk-tooltiptext,
.tk-tooltip:focus .tk-tooltiptext,
.tk-tooltip.tk-active .tk-tooltiptext,
.tk-tooltip:active .tk-tooltiptext {
  display: block;
}

.tooltipEllipses {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1px auto;
  white-space: nowrap;
}

// Mini Tooltip

.tk-tooltip.mini .tk-tooltiptext {
  /* .drop-shadow(@drop-shadow-base);*/
  font-size: 12px;
  line-height: 2;
  padding: 10px;

  &:before, &:after {
    display: none;
  }

  &.tk-tooltip-top {
    bottom: 110%;
  }

  &.tk-tooltip-bottom {
    top: 110%;
  }

  &.tk-tooltip-bottom-right, &.tk-tooltip-bottom-left {
    top: 10%;
  }
}
