.date-range-align {
  margin-top: -0.2rem !important;
  min-width: 12.5rem !important;
  max-width: 14rem !important;
}

.uitk-icon{
  color: #0c55b8;;
}

.uitk-l-grid__col{
  min-width: 9rem;
  padding: 0.5rem !important;
}

.col-content-fit{
  max-width: fit-content !important;
}

.min-width-19-Percent{
  min-width: 19% !important;
}

.min-width-18-Percent{
  min-width: 18% !important;
}

.max-width-12nHalfPercent{
  max-width: 12.5% !important;
}

.uitk-c-form__control{
  height: 30px !important;
}

.width-betwn-16rem-18rem{
  min-width: 16rem !important;
  max-width: 18rem !important;
}

// mat form field: Input field, date picker, dropdown
.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 2.5rem !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple{
  display: none !important;
}

.mat-mdc-icon-button{
  --mat-mdc-button-ripple-color: #ffffff13 !important;
}


.mat-form-field-ripple .ng-tns-c143-0 {
  height: 0px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ffffff !important;
}

.mat-form-field-ripple {
  height: 0px !important;
}

.mat-form-field-label-wrapper {
  width: 0px !important;
}


.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix{
  margin-left: -0.5rem !important;
}



.mat-button-focus-overlay {
  background-color: white !important;
}


.mat-form-field {
  width: 99.5%;
  min-width: 84%;
  color: black;
  height: 30px;
  box-shadow: 0 0 0 1px #888b8d;
  font: 0.75rem inherit, Helvetica, sans-serif !important;
  border-radius: 5px;  
  overflow: hidden;
  flex-wrap: wrap;
}

.mat-date-range-input-container {
  margin-left: 1.5rem !important;
  margin-top: 0.19rem !important; 
}

.uitk-icon-calendar{
  font-size:23px !important;
  margin-top: 14px;
  color: #0c55b8;  
}




mat-date-range-input input {
  color: black !important;
}
.mat-mdc-text-field-wrapper{
  height: 30px !important;
    align-items: center  !important;
    border-color: black  !important;
}
.mat-form-field-outline{
  border-color: black !important;
  border-width : 2px;
}

.mdc-icon-button{
  margin-bottom: 1.725rem !important;
  margin-right: -0.55rem !important;
} 
.mat-form__control--error {
  background-color: #FAEDEC !important;
  box-shadow: 0 0 0 1px #e32315;
}

.uitk-c-dropdown__trigger {
    max-height: 40px !important;
}

.max-width-11Rem{
  max-width: 11rem !important;
}

.max-width-9rem{
  max-width: 9rem !important;
}

