
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$worklist-ui-primary: mat.define-palette(mat.$deep-orange-palette,900);
$worklist-ui-accent: mat.define-palette(mat.$gray-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$worklist-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$worklist-ui-theme: mat.define-light-theme((
  color: (
    primary: $worklist-ui-primary,
    accent: $worklist-ui-accent,
    warn: $worklist-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($worklist-ui-theme);

@import 'app/worklist/worklist.component.scss';
@import 'app/global-search/global-search.component.scss';
@import 'app/basic-search/basic-search.component.scss';
@import 'app/custom-uitk/tooltip-ex/tooltip-ex.component.scss';
@import 'app/timeout/timeout.component.scss';
/* You can add global styles to this file, and also import other style files */
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.wrapper {
  width: 97%;
  z-index: 998;
  min-width: 1200px;
  -ms-flex-direction: row;
  flex-direction: row;
  height: auto;
  display: block;
  margin: 15px 20px;
  height: 97%;
  min-height: 1000px;
  flex-direction: row;
}
body {
  font: 14px Arial, Helvetica, sans-serif !important;
}

.uitk-c-notification__container {
  position     : fixed;
  top          : 6.25rem;
  left         : 50%;
  -ms-transform: translateX(-50%);
  transform    : translateX(-50%);
  margin-left  : auto;
  margin-right : auto;
  z-index      : 99999;
}

.cancelWarning .uitk-c-notification__container {
  top   : 2.3rem !important;
  left  : 40% !important;
  width : 71% !important;
  height: 6% !important;
}

.cancelWarning .uitk-c-notification__container>.uitk-c-notification__content-container>.uitk-c-notification__icon {
  position: relative;
  bottom  : 15%;
}

.duplicateRuleError .uitk-c-notification__container {
  top   : 1.4rem !important;
  left  : 41% !important;
  width : 81% !important;
  height: 8.2% !important;
}

.duplicateRuleError .uitk-c-notification__container>.uitk-c-notification__content-container>.uitk-c-notification__icon {
  position: relative;
  bottom  : 15%;
}

#noticesTabs > div.uitk-c-tabs {
  border: 0 !important;
}

#main-content .uitk-l-grid{
  padding: 0.25rem !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
